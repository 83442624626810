<template>
  <div class="icon-title-card card-border position-relative" @click="emitModalEvent">
    
    <!-- Delete icon row -->
    <div class="row justify-content-end">
      <div class="col-auto"> <!-- This will align the delete icon to the end -->
        <i class="fa fa-times delete-icon" @click.stop="deleteExpense"></i>
      </div>
    </div>
    
    <div class="row align-items-center g-3 mt-0" style="width: 100%;">
      <div class="col-auto">
        <div class="icon-background bg-gradient-warning border-radius-2xl"> <!--this is icon with background-->
          <i class="text-lg opacity-10" style="color: white;" :class="getIconCode()" aria-hidden="true"></i>
        </div>
      </div>
      <div class="col custom-col-width">
        <h6 class="mb-0 text-sm text-uppercase font-weight-bold">{{ expense.name }}</h6>
        <p class="text-muted mb-1" style="font-size: 14px;"><i class="fa fa-clock-o"></i> {{ getFromText() }} till {{ getFromText2() }} </p>
        <p v-if="showChangeTypeAndValue()" class="text-muted mb-1" style="font-size: 14px;"> <i :class=getchangeType()></i> {{ getchangeValue() }}% Annual</p>
      </div>
      <div class="col">
        <h6 class="mb-0 text-sm text-uppercase font-weight-bold text-end">₹{{ formatIndianNumber(expense.amount) }}</h6>
        <p class="text-muted mb-1 text-end" style="font-size: 14px;">{{ expense.frequency }}</p>
        <p class="text-muted mb-1 text-end" style="font-size: 14px;"><i :class="[getEarnerIcon(), selectedOptionColorClass]"></i></p>
      </div>
    </div>
    
  </div>
</template>

<script>
import { useGlobalStore } from '../../store/globalStore'; // Adjust the import path as needed
import { useExpenseStore } from '../../store/expense'; // Import the expense store for deleting the expense entry from expense store

export default {
  name: "IconTitleCard",
  props: {
    expense: Object, // The expense object to display
    type: String, // type can be 'food', 'housing', etc.
  },
  computed: {
    selectedOptionColorClass() {
      // Logic to determine color based on the earner
      if (this.expense.earner === "Me") {
        return 'me-color';
      } else if (this.expense.earner === "Spouse") {
        return 'spouse-color';
      } else {
        // Default color or another logic for when no option is selected or for "Joint"
        return 'joint-color';
      }
    },
  },
  methods: {
    emitModalEvent() {
      let modalName = '';
      switch (this.expense.type) {
        case 'food':
          modalName = 'ModalFood';
          break;
        case 'housing':
          modalName = 'ModalExpHousing';
          break;
        case 'shopping':
          modalName = 'ModalExpShopping';
          break;
        case 'conveyance':
          modalName = 'ModalExpConveyance';
        break;
        case 'loan':
          modalName = 'ModalExpLoan';
        break;
        case 'travel':
          modalName = 'ModalExpTravel';
        break;
        case 'health':
          modalName = 'ModalExpHealth';
        break;
        case 'insurance':
          modalName = 'ModalExpInsurance';
        break;
        case 'custom':
          modalName = 'ModalExpCustom';
        break;
        case 'utility':
          modalName = 'ModalUtilities';
        break;
        // Add more cases as needed for other types
      }
      this.$emit('show-modal', modalName, this.expense);
    },
    showChangeTypeAndValue() {
      if (this.expense.category === 'Loan') {
        return false; // Don't show if the category is 'Loan'
      } else if ((this.expense.category === 'Housing' || this.expense.category === 'Conveyance') && this.expense.ownershiptype === 'Owned - On Mortgage') {
        return false; // Don't show if the category is 'Housing' or 'Conveyance' and ownership is 'Owned - On Mortgage'
      } else {
        return true; // Show for other cases
      }
    },
    getFromText() {
      if (this.expense.from === 'Current Age') {
        return 'Now';
      } else if (this.expense.from === 'Retirement Age') {
        return 'Retirement';
      } else {
        return this.expense.from;
      }
    },
    getFromText2() {
      if (this.expense.till === 'Current Age') {
        return 'Now';
      } else if (this.expense.till === 'Retirement Age') {
        return 'Retirement';
      } else if (this.expense.till === 'Life Expectancy') {
        return 'Life'; 
      } else {
        return this.expense.till;
      }
    },
    formatIndianNumber(number) {
      var x = number;
      x = x.toString();
      var lastThree = x.substring(x.length - 3);
      var otherNumbers = x.substring(0, x.length - 3);
      if (otherNumbers != '')
          lastThree = ',' + lastThree;
      var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
      return res;
    },
    getEarnerIcon() {
      const store = useGlobalStore();
      if (this.expense.earner === 'Me') {
        return store.getGenderIcon;
      } else if (this.expense.earner === 'Spouse') {
        return store.getSpouseGenderIcon;
      } else {
        return 'fa fa-home';
      }
    },
    getchangeValue() {
      return this.expense.change;
    },
    getchangeType() {
      if (this.expense.changetype === 'Increase') {
        return 'fa fa-long-arrow-up';
      } else if (this.expense.changetype === 'Decrease') {
        return 'fa fa-long-arrow-down';
      } else {
        return 'fa fa-long-arrow-right';
      }
    },
    getIconCode() {
      return this.expense.icon;
    },
    deleteExpense(event) {
      // Access the expense store instance
      event.stopPropagation(); // Stop the event from bubbling up
      const expenseStore = useExpenseStore();

      // Find the index of the expense entry to delete
      const index = expenseStore.expenseList.findIndex(
        (item) => item.id === this.expense.id
      );

      // If the expense entry exists in the store, remove it
      if (index !== -1) {
        expenseStore.expenseList.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped>

.custom-col-width {
  flex: 0 0 auto; /* This makes sure your column's width is set explicitly and not just flexing */
  width: 60%; /* or any other value */
}

.icon-title-card {
  display: flex;
  align-items: center;
  margin-bottom: 1px !important;
  padding-left: 15px !important;
  padding-right: 5px !important;
  padding-top: 10px !important;
  padding-bottom: 5px !important;
  cursor: pointer; /* Change cursor to pointer on hover */
}

.delete-icon {
  cursor: pointer; /* Indicates the icon is clickable */
  position: absolute; /* Position the icon absolutely to the top-right */
  top: 0.5rem; /* Adjust the top position as needed */
  right: 1rem; /* Adjust the right position as needed */
  padding-right: 5px !important;
  font-size: 0.75rem; /* Reduced size of the delete icon */
  z-index: 10; /* Ensure the icon is above other elements */
}


.icon-background {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px; /* Adjust the width as needed */
  min-width: 30px;
  height: 30px; /* Adjust the height as needed */
  /* Default background color background-color: rgb(207, 73, 73); */
}

.card-border {
  border: 1px solid #ccc; /* Set the border properties as desired */
  border-radius: 1px !important; /* Adjust the border radius as needed for curved corners */
  margin-bottom: 5px !important; /* Add margin between cards */
}

.title-content {
  display: flex; /* This turns .title-content into a flex container */
  flex-direction: column; /* Stacks children vertically */
  justify-content: center; /* Centers children vertically within the container */
  padding-left: 1rem; /* Keeps your existing left padding */
}
.title-content p {
  margin-bottom: 0; /* Removes the bottom margin */
}

.me-color {
  color: #007bff; /* Replace with your primary color */
}

.spouse-color {
  color: #b324a0; /* Replace with your secondary color */
}

.joint-color {
  color: #34b25a; /* Replace with your secondary color */
}
</style>