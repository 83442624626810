<template>
  <div class="card chart-container">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex align-items-center">
        <h6 class="mb-0">Breakup of Annual Expenses</h6>
      </div>
    </div>

    <div class="p-3 card-body">
      <div class="row">
        <div class="text-center col-12 col-md-6 d-flex flex-column justify-content-center" style="min-height: 350px;">
          <div style="min-height: 197px;">
            <div class="chart">
              <canvas ref = "chartCanvas" height= "197" ></canvas>
            </div>
            <h4 class="font-weight-bold mt-n8">
              <span>{{place}}</span>
              <span class="text-sm d-block text-body">Age - {{place2}}</span>
            </h4>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="table-responsive">
            <table class="table mb-0 align-items-center" style="min-height: 350px;">
              <tbody>
                <tr v-for="(expense, index) in expenses" :key="index">
                  <td>
                    <div class="px-2 py-0 d-flex">
                      <!-- Use expense.label to display the category -->
                      <span class="badge me-3" :style="{ backgroundColor: chartColors[index] }">&nbsp;</span>
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">{{ expense.label }}</h6>
                      </div>
                    </div>
                  </td>
                  <td class="text-sm text-center align-middle">
                    <span class="text-xs font-weight-bold">{{ expense.percentage.toFixed(0) }}%</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "pie-chart",
  props: {
    data: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
    place: {
      type: String,
      required: true,
    },
    place2: {
      type: Number,
      required: true,
    },
    expenses: Array // Define the expenses prop to receive the array from the parent component for showing as a table
  },
  data() {
    return {
      // other data properties...
      chartColors: [
        "#5e72e4",
        "#8392ab",
        "#11cdef",
        "#2dce89",
        "#fb6340",
        "#ffd600", // new yellow
        "#f3a4b5", // new light pink
        "#885af8", // new purple
        "#4fd69c", // new teal
        "#172b4d",  // new dark blue
        "#9D50BB", // Deep purple
        "#1E9600", // Vivid green
        "#FF4E50", // Bright red
        "#F9D423", // Vibrant yellow
        "#76B852", // Earthy green
        "#34e89e", // Mint green
        "#0cebeb", // Sky blue
        "#f80759", // Pinkish red
        "#e1eec3", // Light greenish-yellow
        "#4b6cb7"  // Medium blue
      ],
    };
  },
  mounted() {
    // Initialize and render your chart here
    this.renderChart();
  },
  watch: {
    data: {
      handler() {
        // When the data prop changes, re-render the chart
        this.renderChart();
      },
      deep: true, // Watch for changes within the array
    },
  },
  methods: {
    renderChart() {      

      if (!this.$refs.chartCanvas) { // this is important console, that is delaying the creation of chart
        console.error("chartCanvas reference is not available.");
        return;
      }
      var ctx1 = this.$refs.chartCanvas.getContext("2d");

      // Check if a chart instance already exists, and destroy it
      if (this.chartInstance) {
        console.log("Destroying existing chart instance");
        this.chartInstance.destroy();
      }

      var gradientStroke1 = ctx1.createLinearGradient(0, 230, 0, 50);

      gradientStroke1.addColorStop(1, "rgba(203,12,159,0.2)");
      gradientStroke1.addColorStop(0.2, "rgba(72,72,176,0.0)");
      gradientStroke1.addColorStop(0, "rgba(203,12,159,0)"); //purple colors

      this.chartInstance = new Chart(ctx1, {
        type: "doughnut",
        data: {
          labels: this.labels,
          datasets: [
            {
              weight: 9,
              cutout: 90,
              tension: 0.9,
              pointRadius: 2,
              borderWidth: 2,
              backgroundColor: [
                "#5e72e4",
                "#8392ab",
                "#11cdef",
                "#2dce89",
                "#fb6340",
                "#ffd600", // new yellow
                "#f3a4b5", // new light pink
                "#885af8", // new purple
                "#4fd69c", // new teal
                "#172b4d"  // new dark blue
              ],
              data: this.data, // Keep the data as is if they are numbers
              fill: false, // Fill the area under the line with gradient
              
            }
          ]
        },
        options: {
          locale: 'en-IN',
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
              position: "bottom",
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          
            
        },
      });
      console.log("Chart instance created successfully", this.chartInstance);
    },
  },
  unmounted() {
    // Ensure the chart instance is destroyed when the component is unmounted
    if (this.chartInstance) {
    this.chartInstance.destroy();
    }
  },
};
</script>

<style scoped>

.chart-container {
  height: 100%;
  min-height: 350px;
  width: 100%;
  padding: 12px;
}



</style>