<template>
  <div class="py-4 container-fluid"> <!-- Full-width container -->
    <div class="row">
      <div class="col-lg-12"> <!-- 12 column grid -->
        
        <div class="row mb-4"> <!-- 1st row for graph -->
          <div class="col-lg-12">
            <div class="card z-index-2" style="height: 350px; !important">
              <!-- Conditional rendering based on selected chart type -->
              <template v-if="chartSelectedOption === 'Overview'">
                <OverviewChart :data="combinedData" :labels="overviewLabels"/>
              </template>
              
            </div>
          </div>
        </div>
        <div class="mb-4 mt-4 d-flex justify-content-center align-items-center"><!-- 2nd row for analyse button-->
          <ArgonButton class="mx-2" style="width: 150px; background-color: #8272e8; color: white;" @click="openAnalyzePage" >Analyze</ArgonButton>
          <ArgonButton class="mx-2" style="width: 150px; background-color: #98baeb; color: white;" @click="handleSave">Save</ArgonButton>
        </div>
        <div id="event-container-row" class="row pa-0 d-flex flex-wrap mx-n1"> <!-- Bottom row with 3 cards -->
          <div class="col-lg-4 col-md-6 col-12 px-1 mb-3"> <!-- Income -->
            <div class="card" style="border-radius: 1px;">
              <div class="icon-title-card card-border">
                <div class="d-flex justify-content-between align-items-center" style="width: 100%;"> <!-- Align icon and text to the left, and button to the right -->
                  <div class="d-flex align-items-center"> <!-- Group icon and text together -->
                    <div class="icon-background bg-gradient-primary border-radius-2xl"> <!-- Icon with background -->
                      <i class="text-lg opacity-10" style="color: white;" :class="'fa fa-money-bill'" aria-hidden="true"></i>
                    </div>
                    <div class="title-content"> <!-- Heading & subtitle -->
                      <h6 class="mb-1 text-sm text-uppercase font-weight-bold ">Income</h6>
                      <p>Add your income(s)</p>
                    </div>
                  </div>
                  <!-- Add button -->
                  <button @click="showModal" class="btn rounded-circle p-2 income-hover-color" style="width: 35px; height: 35px;">
                    <i class="text-lg ni ni-fat-add"></i>
                  </button>
                  <MyModal ref="myModalComponent"/>
                </div>
              </div>

              <div class="icon-title-card card-border d-flex justify-content-between align-items-center px-3 py-2" style="height: 100px;">
                <h6 class="mb-0 py-1 text-sm font-weight-bold">Current Year Income :</h6>
                <h6 class="mb-0 py-1 text-sm font-weight-bolder text-end text-primary">₹{{ formatIndianNumber(totalIncomeAmount) }}</h6>
              </div>
              
              <div class="card">
                <TempCard v-for="income in incomeList" :key="income.name" :income="income" :type="income.type" @show-modal="openSpecificModal"/> 

                <!-- Conditionally render modals based on the store -->
                <ModalSalary ref="ModalSalary" @save-income="updateIncome" />
                <ModalFreelancing ref="ModalFreelancing" @save-income="updateIncome" />
                <ModalCommission ref="ModalCommission" @save-income="updateIncome"/>
                <ModalSideBusiness ref="ModalSideBusiness" @save-income="updateIncome"/>
                <ModalCustomIncome ref="ModalCustomIncome" @save-income="updateIncome"/>
                <ModalBonus ref="ModalBonus" @save-income="updateIncome"/>
                <!-- Add other modals similarly -->
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 px-1 mb-3"> <!-- Expenses -->
            <div class="card" style="border-radius: 1px;">
              <div class="icon-title-card card-border">
                <div class="d-flex justify-content-between align-items-center" style="width: 100%;"> <!-- Align icon and text to the left, and button to the right -->
                  <div class="d-flex align-items-center"> <!-- Group icon and text together -->
                    <div class="icon-background bg-gradient-warning border-radius-2xl"> <!-- Icon with background -->
                      <i class="text-lg opacity-10" style="color: white;" :class="'ni ni-cart'" aria-hidden="true"></i>
                    </div>
                    <div class="title-content"> <!-- Text content -->
                      <h6 class="mb-1 text-sm text-uppercase font-weight-bold ">Expenses</h6>
                      <p>Add your expenses</p>
                    </div>
                  </div>
                  <!-- Right button -->
                  
                  <button @click="showExpenseModal" class="btn rounded-circle p-2 expense-hover-color" style="width: 35px; height: 35px;">
                    <i class="text-lg ni ni-fat-add"></i>
                  </button>
                  <ExpenseModal ref="ExpenseModalComponent"/>
                </div>
              </div>

              <div class="icon-title-card card-border d-flex justify-content-between align-items-center px-3 py-2" style="height: 100px;">
                <h6 class="mb-0 py-1 text-sm font-weight-bold">Current Year Expenses :</h6>
                <h6 class="mb-0 py-1 text-sm font-weight-bolder text-end text-warning">₹{{ formatIndianNumber(totalExpenseAmount)}}</h6>
              </div>

              <div class="card">
                <ExpenseCard v-for="expense in expenseList" :key="expense.name" :expense="expense" :type="expense.type" @show-modal="openExpenseSpecificModal"/>

                <!-- Conditionally render modals based on the store -->
                <ModalFood ref="ModalFood" @save-expense="updateExpense" />
                <ModalExpHousing ref="ModalExpHousing" @save-expense="updateExpense" />
                <ModalExpShopping ref="ModalExpShopping" @save-expense="updateExpense" />
                <ModalExpConveyance ref="ModalExpConveyance" @save-expense="updateExpense" />
                <ModalExpLoan ref="ModalExpLoan" @save-expense="updateExpense" />
                <ModalExpTravel ref="ModalExpTravel" @save-expense="updateExpense" />
                <ModalExpHealth ref="ModalExpHealth" @save-expense="updateExpense" />
                <ModalExpInsurance ref="ModalExpInsurance" @save-expense="updateExpense" />
                <ModalExpCustom ref="ModalExpCustom" @save-expense="updateExpense" />
                <ModalUtilities ref="ModalUtilities" @save-expense="updateExpense" />
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 px-1 mb-3"> <!-- Savings -->
            <div class="card" style="border-radius: 1px;">
              <div class="icon-title-card card-border">
                <div class="d-flex justify-content-between align-items-center" style="width: 100%;"> <!-- Align icon and text to the left, and button to the right -->
                  <div class="d-flex align-items-center"> <!-- Group icon and text together -->
                    <div class="icon-background bg-gradient-success border-radius-2xl"> <!-- Icon with background -->
                      <i class="text-lg opacity-10" style="color: white;" :class="'fa fa-dollar'" aria-hidden="true"></i>
                    </div>
                    <div class="title-content"> <!-- Text content -->
                      <h6 class="mb-1 text-sm text-uppercase font-weight-bold ">Savings</h6>
                      <p>Add your savings</p>
                    </div>
                  </div>
                  <!-- Right button -->
                  
                  <button @click="showSavingssModal" class="btn rounded-circle p-2 savings-hover-color" style="width: 35px; height: 35px;">
                    <i class="text-lg ni ni-fat-add"></i>
                  </button>
                  <ModalSavings ref="ModalSavingsComponent"/>
                </div>
              </div>

              <div class="icon-title-card card-border d-flex justify-content-between align-items-center px-3 py-2" style="height: 100px;">
                <div>
                  <h6 class="mb-0 py-1 text-sm font-weight-bold">Opening Balance :</h6>
                  <h6 class="mb-0 py-1 text-sm font-weight-bold">Saved this Year :</h6>
                  <h6 class="mb-0 py-1 text-sm font-weight-bold">Left to allocate :</h6>
                  
                </div>
                <div>
                  <h6 class="mb-0 py-1 text-sm font-weight-bolder text-end text-primary">₹{{ formatIndianNumber(totalSavingAmount) }}</h6>
                  <h6 class="mb-0 py-1 text-sm font-weight-bolder text-end text-primary">₹{{ formatIndianNumber(totalSavingNewAmount) }}</h6>
                  <h6 class="mb-0 py-1 text-sm font-weight-bolder text-end text-primary">₹{{ formatIndianNumber(leftToAllocate) }}</h6>
                </div>
                
              </div>

              
              <div class="card">
                <SavingCard v-for="saving in savingList" :key="saving.name" :saving="saving" :type="saving.type" @show-modal="openSavingSpecificModal"/>

                <!-- Conditionally render modals based on the store -->
                <ModalSavFD ref="ModalSavFD" @save-saving="updateSaving" />
                <ModalSavRD ref="ModalSavRD" @save-saving="updateSaving" />
                <ModalSavMF ref="ModalSavMF" @save-saving="updateSaving" />
                <ModalSavStocks ref="ModalSavStocks" @save-saving="updateSaving" />
                <ModalSavGold ref="ModalSavGold" @save-saving="updateSaving" />
                <ModalSavPF ref="ModalSavPF" @save-saving="updateSaving" />
                <ModalSavNPS ref="ModalSavNPS" @save-saving="updateSaving" />
                <ModalSavCustom ref="ModalSavCustom" @save-saving="updateSaving" />
                <ModalSavCash ref="ModalSavCash" @save-saving="updateSaving" />
    
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>    
</template>

<script>

import OverviewChart from "../examples/Charts/OverviewChart.vue";
import MyModal from '../components/MyModal.vue';
import ExpenseModal from "../components/ExpenseModal.vue";
import ModalSavings from "../components/ModalSavings.vue";
import {useGlobalStore} from '../store/globalStore';
import { useIncomeStore } from '../store/income';
import { useExpenseStore } from "../store/expense";
import { useSavingStore } from "../store/savings";
import TempCard from '../examples/Cards/TempCard.vue';
import ExpenseCard from "../examples/Cards/ExpenseCard.vue";
import SavingCard from "../examples/Cards/SavingCard.vue";
import ArgonButton from "../components/ArgonButton.vue";
import ModalSalary from "../components/ModalSalary.vue";
import ModalFreelancing from "../components/ModalFreelancing.vue";
import ModalCommission from "../components/ModalCommission.vue";
import ModalSideBusiness from "../components/ModalSideBusiness.vue";
import ModalCustomIncome from "../components/ModalCustomIncome.vue";
import ModalBonus from "../components/ModalBonus.vue";
import ModalFood from "../components/ModalFood.vue";
import ModalExpHousing from '../components/ModalExpHousing.vue';
import ModalExpShopping from '../components/ModalExpShopping.vue';
import ModalExpConveyance from '../components/ModalExpConveyance.vue';
import ModalExpLoan from '../components/ModalExpLoan.vue';
import ModalExpTravel from '../components/ModalExpTravel.vue';
import ModalExpHealth from '../components/ModalExpHealth.vue';
import ModalExpInsurance from '../components/ModalExpInsurance.vue';
import ModalExpCustom from '../components/ModalExpCustom.vue';
import ModalUtilities from "../components/ModalUtilities.vue";
import ModalSavFD from '../components/ModalSavFD.vue';
import ModalSavRD from '../components/ModalSavRD.vue';
import ModalSavMF from '../components/ModalSavMF.vue';
import ModalSavStocks from '../components/ModalSavStocks.vue';
import ModalSavGold from '../components/ModalSavGold.vue';
import ModalSavPF from '../components/ModalSavPF.vue';
import ModalSavNPS from '../components/ModalSavNPS.vue';
import ModalSavCustom from '../components/ModalSavCustom.vue';
import ModalSavCash from '../components/ModalSavCash.vue';
import { auth } from "../main";



export default {
  data() {
    return {
      chartSelectedOption: 'Overview',
    };
  },

  

  methods: {
    async handleSave() {
      const incomeStore = useIncomeStore();
      const expenseStore = useExpenseStore();
      const savingStore = useSavingStore();
      const globalStore = useGlobalStore();

      // Check if user is logged in
      const user = auth.currentUser;
      
      if (!user) {
        // User is not logged in, save incomeList to localStorage
        
        localStorage.setItem("unsavedIncomeList", JSON.stringify(incomeStore.incomeList));
        localStorage.setItem("unsavedExpenseList", JSON.stringify(expenseStore.expenseList));
        localStorage.setItem("unsavedSavingList", JSON.stringify(savingStore.savingList));
        localStorage.setItem("unsavedGlobalStore", JSON.stringify({
          gender: globalStore.gender,
          genderIcon: globalStore.genderIcon,
          spouseGender: globalStore.spouseGender,
          spouseGenderIcon: globalStore.spouseGenderIcon,
          currentAge: globalStore.currentAge,
          retirementAge: globalStore.retirementAge,
          livetillAge: globalStore.livetillAge,
          spouseCurrentAge: globalStore.spouseCurrentAge,
          spouseRetirementAge: globalStore.spouseRetirementAge,
          spouseLiveTillAge: globalStore.spouseLiveTillAge,
          inflationRate: globalStore.inflationRate,
          returnRate: globalStore.returnRate
        }));

        
        // Redirect to Signup page
        this.$router.push("/signup");
      } else {
        
        // User is logged in, save directly to Firestore
        try {
          const userId = user.uid;
          
          await incomeStore.saveIncomeToFirestore(userId);
          await expenseStore.saveExpenseToFirestore(userId);
          await savingStore.saveSavingToFirestore(userId);
          await globalStore.saveGlobalStoreToFirestore(userId);
          
          
        } catch (error) {
          console.error("Error saving data to Firestore:", error);
        }
      }
    },
    openSpecificModal(modalName, income) {
      if (this.$refs[modalName]) {
        this.$refs[modalName].openModal(income);
      }
    },
    openExpenseSpecificModal(modalName, expense) {
      if (this.$refs[modalName]) {
        this.$refs[modalName].openModal(expense);
      }
    },
    openSavingSpecificModal(modalName, saving) {
      if (this.$refs[modalName]) {
        this.$refs[modalName].openModal(saving);
      }
    },
    updateIncome(updatedIncome) {
      const incomeStore = useIncomeStore();
      incomeStore.updateIncome(updatedIncome);
    },
    updateExpense(updatedExpense) {
      const expenseStore = useExpenseStore();
      expenseStore.updateExpense(updatedExpense);
    },
    updateSaving(updatedSaving) {
      const savingStore = useSavingStore();
      savingStore.updateSaving(updatedSaving);
    },
    showModal() {
      this.$refs.myModalComponent.openModal();
    },
    showExpenseModal(){
      this.$refs.ExpenseModalComponent.openModal();
    },
    showAccountsModal() {
      this.$refs.ModalAccountsComponent.openModal();
    },
    showSavingssModal() {
      this.$refs.ModalSavingsComponent.openModal();
    },
    openAnalyzePage() {
      this.$router.push('/analyze')
    },
    formatIndianNumber(number) {
      var x = number;
      x = x.toString();
      var lastThree = x.substring(x.length - 3);
      var otherNumbers = x.substring(0, x.length - 3);
      if (otherNumbers != '')
          lastThree = ',' + lastThree;
      var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
      return res;
    },
    
  },
  components: {
    MyModal,
    ExpenseModal,
    ModalSavings,
    TempCard,
    ExpenseCard,
    SavingCard,
    ArgonButton,
    ModalSalary,
    ModalCommission,
    ModalSideBusiness,
    ModalFreelancing,
    ModalCustomIncome,
    ModalFood,
    ModalExpHousing,
    ModalExpShopping,
    ModalExpConveyance,
    ModalExpLoan,
    ModalExpTravel,
    ModalExpHealth,
    ModalExpInsurance,
    ModalExpCustom,
    ModalUtilities,
    ModalSavFD,
    ModalSavRD,
    ModalSavMF,
    ModalSavStocks,
    ModalSavGold,
    ModalSavPF,
    ModalSavNPS,
    ModalSavCustom,
    ModalSavCash,
    ModalBonus,
    OverviewChart
  },
  computed: {
    
    checkAge(){
      const globalStore = useGlobalStore();
      const fromYear = globalStore.currentAge; // Access the starting years
      return fromYear;
    },
    overviewLabels() {
      const globalStore = useGlobalStore();
      const fromYears = globalStore.currentAge; // Access the starting years
      const tillYears = globalStore.livetillAge; // Access the ending years

      const labels = [];
      for (let year = fromYears; year <= tillYears; year++) {
        labels.push(year);
      }

      
      return labels;
    },
    incomeList() {
      return useIncomeStore().incomeList;
    },
    expenseList() {
      return useExpenseStore().expenseList;
    },
    savingList() {
      return useSavingStore().savingList;
    },

    totalIncomeAmount() {
      // Check if any of the required arrays are empty
      if (!this.incomeList || this.incomeList.length === 0) {
        // Return 0 if the incomeList is empty or not available
        return 0;
      }
      
      // Get the earliest year from the fromValues array
      const earliestYear = Math.min(...this.incfromFromStore);
      
      // Filter the incomeList to include only entries for the earliest year
      const incomeForEarliestYear = this.incomeList.filter(income => parseInt(income.from) <= earliestYear && earliestYear <= parseInt(income.till));
      
      // Calculate the total income amount for the earliest year
      const totalIncomeForEarliestYear = incomeForEarliestYear.reduce((total, income) => {
        if (income.frequency === "Once in life") { 
          // For "Once in life" income, add the amount only once
          return total + parseInt(income.amount);
        } else if (income.frequency === "Daily") { 
          // For daily income, multiply the amount by 365
          return total + (parseInt(income.amount) * 365);
        } else if (income.frequency === "Weekly") { 
          // For weekly income, multiply the amount by 52
          return total + (parseInt(income.amount) * 52);
        } else if (income.frequency === "Monthly") { 
          // For monthly income, multiply the amount by 12
          return total + (parseInt(income.amount) * 12);
        } else { 
          // For annual income, add the amount as it is
          return total + parseInt(income.amount);
        }
      }, 0);
      
      // Return the total income amount for the earliest year
      return totalIncomeForEarliestYear;
    },
    incChangePercFromStore() {
      const changeValues = this.incomeList.map(income => parseFloat(income.change));
      
      return changeValues;
    },
    incChangeTypeFromStore() {
      const changeTypeValues = this.incomeList.map(income => income.changetype);
      
      return changeTypeValues;
    },
    IncomeAmount() {
      const incValues = this.incomeList.map(income => parseFloat(income.amount));
      return incValues;
    },
    incfromFromStore() {
      const fromValues = this.incomeList.map(income => parseFloat(income.from));
      
      return fromValues;
    },
    inctillFromStore() {
      const tillValues = this.incomeList.map(income => parseFloat(income.till));
      
      return tillValues;
    },
    incFreqFromStore() {
      const incFreqValues = this.incomeList.map(income => income.frequency);
      
      return incFreqValues;
    },
    calculateIncome() {
      // Check if any of the required arrays are empty
      if (
        !this.IncomeAmount ||
        !this.incChangePercFromStore ||
        !this.incChangeTypeFromStore ||
        !this.incfromFromStore ||
        !this.inctillFromStore ||
        !this.incFreqFromStore ||
        this.IncomeAmount.length === 0 ||
        this.incChangePercFromStore.length === 0 ||
        this.incChangeTypeFromStore.length === 0 ||
        this.incfromFromStore.length === 0 ||
        this.inctillFromStore.length === 0 ||
        this.incFreqFromStore.length === 0
      ) {
        // Return an empty array or any default value if the arrays are empty or not available
        return [];
      }
      
      const incomeEntries = this.IncomeAmount; // Access the total income amounts from the computed property
      const changePercs = this.incChangePercFromStore; // Access the change percentages
      const fromYears = this.incfromFromStore; // Access the starting years
      const tillYears = this.inctillFromStore; // Access the ending years
      const frequencies = this.incFreqFromStore; // Access the income frequencies

      // Determine the range of years
      const minYear = Math.min(...fromYears);
      const maxYear = Math.max(...tillYears);
      const numYears = maxYear - minYear + 1;

      // Initialize array to store total income for each year
      const incomeData = new Array(numYears).fill(0);

      for (let i = 0; i < incomeEntries.length; i++) {
        let changePerc = changePercs[i];
        // Check the change type for the current income entry
        if (this.incChangeTypeFromStore[i] === 'Increase' || this.incChangeTypeFromStore[i] === 'Match Inflation') {
          changePerc = 1 + changePerc / 100; // Increase or match inflation
        } else if (this.incChangeTypeFromStore[i] === 'Decrease') {
          changePerc = 1 - changePerc / 100; // Decrease
        }


        // Calculate the annual income based on frequency
        let annualIncome = 0;
        if (frequencies[i] === "Monthly") {
          annualIncome = incomeEntries[i] * 12;
        } else if (frequencies[i] === "Daily") {
          annualIncome = incomeEntries[i] * 365;
        } else if (frequencies[i] === "Weekly") {
          annualIncome = incomeEntries[i] * 52;
        } else if (frequencies[i] === "Annual") {
          annualIncome = incomeEntries[i];
        } else if (frequencies[i] === "Once in life") {
          // For "Once in life" scenario, income occurs only once at a specific age
          // Determine the year when the income occurs
          const onceInLifeYear = fromYears[i]; // Assuming the income occurs at age 40
          if (onceInLifeYear >= minYear && onceInLifeYear <= maxYear) {
            // Update the total income for the corresponding year
            incomeData[onceInLifeYear - minYear] += Math.round(incomeEntries[i]);
          }
          continue; // Skip the rest of the loop for "Once in life" income
        }

        for (let year = fromYears[i]; year <= tillYears[i]; year++) {
          let income = annualIncome;
          for (let j = 0; j < year - fromYears[i]; j++) {
            income *= changePerc; // Apply the change percentage
          }
          // Update the total income for the corresponding year
          incomeData[year - minYear] += Math.round(income);
        }
      }

      
      return incomeData;
    },
    calculateIndividualIncome() {
      // Check if any of the required arrays are empty
      if (
        !this.IncomeAmount ||
        !this.incChangePercFromStore ||
        !this.incChangeTypeFromStore ||
        !this.incfromFromStore ||
        !this.inctillFromStore ||
        !this.incFreqFromStore ||
        this.IncomeAmount.length === 0 ||
        this.incChangePercFromStore.length === 0 ||
        this.incChangeTypeFromStore.length === 0 ||
        this.incfromFromStore.length === 0 ||
        this.inctillFromStore.length === 0 ||
        this.incFreqFromStore.length === 0
      ) {
        // Return an empty array or any default value if the arrays are empty or not available
        return [];
      }

      // Initialize an empty array to store income data for each income source
      const incomeData = [];

      // Loop through each income source
      for (let i = 0; i < this.IncomeAmount.length; i++) {
        const incomeEntry = this.IncomeAmount[i];
        let changePerc = this.incChangePercFromStore[i];
        const changeType = this.incChangeTypeFromStore[i]; 
        const fromYear = this.incfromFromStore[i];
        const tillYear = this.inctillFromStore[i];
        const frequency = this.incFreqFromStore[i];
        const name = this.incomeList[i].name; // Accessing the name property

        // Determine the appropriate change percentage based on the change type
        if (changeType === 'Increase' || changeType === 'Match Inflation') {
            changePerc = 1 + changePerc / 100; // Increase or match inflation
        } else if (changeType === 'Decrease') {
            changePerc = 1 - changePerc / 100; // Decrease
        }

        // Calculate income data for the current income source
        const incomeForSource = [];

        // Pad the array with zeroes for years before `fromYear` if necessary
        const padding = new Array(fromYear - Math.min(...this.incfromFromStore)).fill(0);
        incomeForSource.push(...padding);

        for (let year = fromYear; year <= tillYear; year++) {
          // Calculate the income for the current year based on frequency and change percentage
          let annualIncome = incomeEntry;

          if (frequency === "Monthly") {
            annualIncome *= 12;
          } else if (frequency === "Daily") {
            annualIncome *= 365;
          } else if (frequency === "Weekly") {
            annualIncome *= 52;
          }

          for (let j = 0; j < year - fromYear; j++) {
            annualIncome *= changePerc; // Apply the change percentage
          }

          // Push the income for the current year to the array
          incomeForSource.push(Math.round(annualIncome));
        }

        // console.log(name + " starts at age " + fromYear + " and ends at age " + tillYear);

        // Push the income data for the current source to the main income data array
        incomeData.push({ data: incomeForSource, name });
      }

      
      // Return the array containing income data for each income source
      return incomeData;
    },
    calculateLabels() {
      // Check if any of the required arrays are empty
      if (
        !this.IncomeAmount ||
        !this.incChangePercFromStore ||
        !this.incChangeTypeFromStore ||
        !this.incfromFromStore ||
        !this.inctillFromStore ||
        this.IncomeAmount.length === 0 ||
        this.incChangePercFromStore.length === 0 ||
        this.incChangeTypeFromStore.length === 0 ||
        this.incfromFromStore.length === 0 ||
        this.inctillFromStore.length === 0
      ) {
        // Return an empty array or any default value if the arrays are empty or not available
        return [];
      }
      const fromYears = this.incfromFromStore; // Access the starting years
      const tillYears = this.inctillFromStore; // Access the ending years

      const labels = [];
      for (let year = fromYears[0]; year <= Math.max(...tillYears); year++) {
        labels.push(year);
      }

      
      return labels;
    },



    totalExpenseAmount() {
      // Check if any of the required arrays are empty
      if (!this.expenseList || this.expenseList.length === 0) {
        // Return 0 if the incomeList is empty or not available
        return 0;
      }
      
      // Get the earliest year from the fromValues array
      const earliestYear = Math.min(...this.expfromFromStore);
      
      // Filter the incomeList to include only entries for the earliest year
      const expenseForEarliestYear = this.expenseList.filter(expense => parseInt(expense.from) <= earliestYear && earliestYear <= parseInt(expense.till));
      
      // Calculate the total income amount for the earliest year
      const totalExpenseForEarliestYear = expenseForEarliestYear.reduce((total, expense) => {
        if (expense.frequency === "Once in life") { 
          // For "Once in life" income, add the amount only once
          return total + parseInt(expense.amount);
        } else if (expense.frequency === "Daily") { 
          // For daily income, multiply the amount by 365
          return total + (parseInt(expense.amount) * 365);
        } else if (expense.frequency === "Weekly") { 
          // For weekly expense, multiply the amount by 52
          return total + (parseInt(expense.amount) * 52);
        } else if (expense.frequency === "Monthly") { 
          // For monthly income, multiply the amount by 12
          return total + (parseInt(expense.amount) * 12);
        } else { 
          // For annual income, add the amount as it is
          return total + parseInt(expense.amount);
        }
      }, 0);
      
      // Return the total income amount for the earliest year
      return totalExpenseForEarliestYear;
    },
    expChangePercFromStore() {
      const changeValues = this.expenseList.map(expense => parseFloat(expense.change));
      
      return changeValues;
    },
    expChangeTypeFromStore() {
      const changeTypeValues = this.expenseList.map(expense => expense.changetype);
      
      return changeTypeValues;
    },
    ExpenseAmount() {
      const expValues = this.expenseList.map(expense => parseFloat(expense.amount));
      return expValues;
    },
    expfromFromStore() {
      const fromValues = this.expenseList.map(expense => parseFloat(expense.from));
      
      return fromValues;
    },
    exptillFromStore() {
      const tillValues = this.expenseList.map(expense => parseFloat(expense.till));
      
      return tillValues;
    },
    expFreqFromStore() {
      const expFreqValues = this.expenseList.map(expense => expense.frequency);
      
      return expFreqValues;
    },
    calculateExpense() {
      // Check if any of the required arrays are empty
      if (
        !this.ExpenseAmount ||
        !this.expChangePercFromStore ||
        !this.expChangeTypeFromStore ||
        !this.expfromFromStore ||
        !this.exptillFromStore ||
        !this.expFreqFromStore ||
        this.ExpenseAmount.length === 0 ||
        this.expChangePercFromStore.length === 0 ||
        this.expChangeTypeFromStore.length === 0 ||
        this.expfromFromStore.length === 0 ||
        this.exptillFromStore.length === 0 ||
        this.expFreqFromStore.length === 0
      ) {
        // Return an empty array or any default value if the arrays are empty or not available
        return [];
      }
      
      const expenseEntries = this.ExpenseAmount; // Access the total income amounts from the computed property
      const changePercs = this.expChangePercFromStore; // Access the change percentages
      const fromYears = this.expfromFromStore; // Access the starting years
      const tillYears = this.exptillFromStore; // Access the ending years
      const frequencies = this.expFreqFromStore; // Access the income frequencies

      // Determine the range of years
      const minYear = Math.min(...fromYears);
      const maxYear = Math.max(...tillYears);
      const numYears = maxYear - minYear + 1;

      // Initialize array to store total income for each year
      const expenseData = new Array(numYears).fill(0);

      for (let i = 0; i < expenseEntries.length; i++) {
        let changePerc = changePercs[i];
        // Check the change type for the current income entry
        if (this.expChangeTypeFromStore[i] === 'Increase' || this.expChangeTypeFromStore[i] === 'Match Inflation') {
          changePerc = 1 + changePerc / 100; // Increase or match inflation
        } else if (this.expChangeTypeFromStore[i] === 'Decrease') {
          changePerc = 1 - changePerc / 100; // Decrease
        }


        // Calculate the annual income based on frequency
        let annualExpense = 0;
        if (frequencies[i] === "Monthly") {
          annualExpense = expenseEntries[i] * 12;
        } else if (frequencies[i] === "Daily") {
          annualExpense = expenseEntries[i] * 365;
        } else if (frequencies[i] === "Weekly") {
          annualExpense = expenseEntries[i] * 52;
        } else if (frequencies[i] === "Annual") {
          annualExpense = expenseEntries[i];
        } else if (frequencies[i] === "Once in life") {
          // For "Once in life" scenario, income occurs only once at a specific age
          // Determine the year when the income occurs
          const onceInLifeYear = fromYears[i]; // Assuming the income occurs at age 40
          if (onceInLifeYear >= minYear && onceInLifeYear <= maxYear) {
            // Update the total income for the corresponding year
            expenseData[onceInLifeYear - minYear] += Math.round(expenseEntries[i]);
          }
          continue; // Skip the rest of the loop for "Once in life" income
        }

        for (let year = fromYears[i]; year <= tillYears[i]; year++) {
          let expense = annualExpense;
          for (let j = 0; j < year - fromYears[i]; j++) {
            expense *= changePerc; // Apply the change percentage
          }
          // Update the total income for the corresponding year
          expenseData[year - minYear] += Math.round(expense);
        }
      }

      
      return expenseData;
    },
    calculateIndividualExpense() {
      // Check if any of the required arrays are empty
      if (
        !this.ExpenseAmount ||
        !this.expChangePercFromStore ||
        !this.expChangeTypeFromStore ||
        !this.expfromFromStore ||
        !this.exptillFromStore ||
        !this.expFreqFromStore ||
        this.ExpenseAmount.length === 0 ||
        this.expChangePercFromStore.length === 0 ||
        this.expChangeTypeFromStore.length === 0 ||
        this.expfromFromStore.length === 0 ||
        this.exptillFromStore.length === 0 ||
        this.expFreqFromStore.length === 0
      ) {
        // Return an empty array or any default value if the arrays are empty or not available
        return [];
      }

      // Initialize an empty array to store income data for each income source
      const expenseData = [];

      // Loop through each income source
      for (let i = 0; i < this.ExpenseAmount.length; i++) {
        const expenseEntry = this.ExpenseAmount[i];
        let changePerc = this.expChangePercFromStore[i];
        const changeType = this.expChangeTypeFromStore[i]; 
        const fromYear = this.expfromFromStore[i];
        const tillYear = this.exptillFromStore[i];
        const frequency = this.expFreqFromStore[i];
        const name = this.expenseList[i].name; // Accessing the name property

        // Determine the appropriate change percentage based on the change type
        if (changeType === 'Increase' || changeType === 'Match Inflation') {
            changePerc = 1 + changePerc / 100; // Increase or match inflation
        } else if (changeType === 'Decrease') {
            changePerc = 1 - changePerc / 100; // Decrease
        }

        // Calculate income data for the current income source
        const expenseForSource = [];

        // Pad the array with zeroes for years before `fromYear` if necessary
        const padding = new Array(fromYear - Math.min(...this.expfromFromStore)).fill(0);
        expenseForSource.push(...padding);

        for (let year = fromYear; year <= tillYear; year++) {
          // Calculate the income for the current year based on frequency and change percentage
          let annualExpense = expenseEntry;

          if (frequency === "Monthly") {
            annualExpense *= 12;
          } else if (frequency === "Daily") {
            annualExpense *= 365;
          } else if (frequency === "Weekly") {
            annualExpense *= 52;
          }

          for (let j = 0; j < year - fromYear; j++) {
            annualExpense *= changePerc; // Apply the change percentage
          }

          // Push the income for the current year to the array
          expenseForSource.push(Math.round(annualExpense));
        }

        // console.log(name + " starts at age " + fromYear + " and ends at age " + tillYear);

        // Push the income data for the current source to the main income data array
        expenseData.push({ data: expenseForSource, name });
      }

      
      // Return the array containing income data for each income source
      return expenseData;
    },
    calculateExpenseLabels() {
      // Check if any of the required arrays are empty
      if (
        !this.ExpenseAmount ||
        !this.expChangePercFromStore ||
        !this.expChangeTypeFromStore ||
        !this.expfromFromStore ||
        !this.exptillFromStore ||
        this.ExpenseAmount.length === 0 ||
        this.expChangePercFromStore.length === 0 ||
        this.expChangeTypeFromStore.length === 0 ||
        this.expfromFromStore.length === 0 ||
        this.exptillFromStore.length === 0
      ) {
        // Return an empty array or any default value if the arrays are empty or not available
        return [];
      }
      const fromYears = this.expfromFromStore; // Access the starting years
      const tillYears = this.exptillFromStore; // Access the ending years

      const labels = [];
      for (let year = fromYears[0]; year <= Math.max(...tillYears); year++) {
        labels.push(year);
      }

      
      return labels;
    },



    savChangePercFromStore() {
      const changeValues = this.savingList.map(saving => parseFloat(saving.change));
      
      return changeValues;
    },
    SavingAmount() {
      const savValues = this.savingList.map(saving => parseFloat(saving.amount));
      return savValues;
    },
    SavingNewAmount() {
      const savValues = this.savingList.map(saving => parseFloat(saving.newAmount));
      return savValues;
    },
    savfromFromStore() {
      const fromValues = this.savingList.map(saving => parseFloat(saving.from));
      
      return fromValues;
    },
    savtillFromStore() {
      const tillValues = this.savingList.map(saving => parseFloat(saving.till));
      
      return tillValues;
    },
    savFreqFromStore() {
      const savFreqValues = this.savingList.map(saving => saving.frequency);
      
      return savFreqValues;
    },
    savIncrementPercFromStore() {
      const incValues = this.savingList.map(saving => parseFloat(saving.annualIncrement));
      
      return incValues;
    },
    calculateSaving() { // needed for showing Savings line in Overview graph
      // Check if any of the required arrays are empty
      if (
        !this.SavingAmount ||
        !this.SavingNewAmount ||
        !this.savChangePercFromStore ||
        !this.savfromFromStore ||
        !this.savtillFromStore ||
        !this.savFreqFromStore ||
        !this.savIncrementPercFromStore ||
        this.SavingAmount.length === 0 ||
        this.SavingNewAmount.length === 0 ||
        this.savChangePercFromStore.length === 0 ||
        this.savfromFromStore.length === 0 ||
        this.savtillFromStore.length === 0 ||
        this.savFreqFromStore.length === 0 ||
        this.savIncrementPercFromStore.length === 0
      ) {
        // Return an empty array or any default value if the arrays are empty or not available
        return [];
      }

      const savingAmountEntries = this.SavingAmount; // Access the total saving amounts from the computed property
      const savingNewAmountEntries = this.SavingNewAmount; // Access the total saving amounts from the computed property
      const changePercs = this.savChangePercFromStore; // Access the change percentages
      const fromYears = this.savfromFromStore; // Access the starting years
      const tillYears = this.savtillFromStore; // Access the ending years
      const frequencies = this.savFreqFromStore; // Access the frequencies
      const incrementPercs = this.savIncrementPercFromStore; // Access the annual increment percentages

      // Determine the range of years
      const minYear = Math.min(...fromYears);
      const maxYear = Math.max(...tillYears);
      const numYears = maxYear - minYear + 1;

      // Initialize array to store total saving for each year
      const savingData = new Array(numYears).fill(0);

      for (let i = 0; i < savingAmountEntries.length; i++) {
        let changePerc = changePercs[i];
        changePerc = 1 + changePerc / 100; // Increase or match inflation

        let annualFrequentSaving = savingNewAmountEntries[i]; // Initialize with the recurring contribution

        // Adjust annual saving based on frequency
        switch (frequencies[i]) {
          case "Monthly":
            annualFrequentSaving *= 12; // Convert monthly contribution to annual saving
            break;
          case "Quarterly":
            annualFrequentSaving *= 4; // Convert daily contribution to annual saving
            break;
          case "Half-Yearly":
            annualFrequentSaving *= 2; // Convert weekly contribution to annual saving
            break;
          // If frequency is not recognized, assume it's already in annual format
        }

        // Add the opening balance to the adjusted annual saving
        let totalSavingForYear = savingAmountEntries[i] + annualFrequentSaving;

        // Calculate the total saving for each year with compound interest and additional contribution
        for (let year = fromYears[i]; year <= tillYears[i]; year++) {
          // Apply the annual increment to the annual saving
          annualFrequentSaving *= (1 + incrementPercs[i] / 100);
          
          // Calculate the interest for the current year
          let interestForYear = totalSavingForYear * (changePerc - 1);

          // Add the interest to the total saving for the current year
          totalSavingForYear += interestForYear;

          // Add the saving to the corresponding year
          savingData[year - minYear] += Math.round(totalSavingForYear);

          // Add the recurring contribution to the total saving for the next year
          totalSavingForYear += annualFrequentSaving;
        }

      }

      
      return savingData;
    },
    calculateOnlyAnnualSaving() { // needed for calculating money put in savings every year which will then be used to calculate leftover amount which is not getting in leftToAllocate function
      // doesn't consider opening balance. Only amount put into savings every year (including yoy increment) and not the value of savings, just the amount saved
      // Check if any of the required arrays are empty
      if (
        !this.SavingNewAmount ||
        !this.savChangePercFromStore ||
        !this.savfromFromStore ||
        !this.savtillFromStore ||
        !this.savFreqFromStore ||
        !this.savIncrementPercFromStore ||
        this.SavingNewAmount.length === 0 ||
        this.savChangePercFromStore.length === 0 ||
        this.savfromFromStore.length === 0 ||
        this.savtillFromStore.length === 0 ||
        this.savFreqFromStore.length === 0 ||
        this.savIncrementPercFromStore.length === 0
      ) {
        // Return an empty array or any default value if the arrays are empty or not available
        return [];
      }
      
      const globalStore = useGlobalStore();
      const retirementYear = globalStore.retirementAge; // Assume retirementYear is available
      const savingNewAmountEntries = this.SavingNewAmount; // Access the total saving amounts from the computed property
      const changePercs = this.savChangePercFromStore; // Access the change percentages
      const fromYears = this.savfromFromStore; // Access the starting years
      const tillYears = this.savtillFromStore; // Access the ending years
      const frequencies = this.savFreqFromStore; // Access the frequencies
      const incrementPercs = this.savIncrementPercFromStore; // Access the annual increment percentages

      // Determine the range of years
      const minYear = Math.min(...fromYears);
      // const maxYear = Math.max(...tillYears);
      const maxYear = Math.min(Math.max(...tillYears), retirementYear); // Stop at retirement year
      const numYears = maxYear - minYear + 1;

      // Initialize array to store total saving for each year
      const savingData = new Array(numYears).fill(0);

      for (let i = 0; i < savingNewAmountEntries.length; i++) {
        let changePerc = changePercs[i];
        changePerc = 1 + changePerc / 100; // Increase or match inflation

        let annualFrequentSaving = savingNewAmountEntries[i]; // Initialize with the recurring contribution

        // Adjust annual saving based on frequency
        switch (frequencies[i]) {
          case "Monthly":
            annualFrequentSaving *= 12; // Convert monthly contribution to annual saving
            break;
          case "Quarterly":
            annualFrequentSaving *= 4; // Convert daily contribution to annual saving
            break;
          case "Half-Yearly":
            annualFrequentSaving *= 2; // Convert weekly contribution to annual saving
            break;
          // If frequency is not recognized, assume it's already in annual format
        }

        // for (let year = fromYears[i]; year <= tillYears[i]; year++) {
        for (let year = fromYears[i]; year <= Math.min(tillYears[i], retirementYear); year++) {
          let saving = annualFrequentSaving;
          for (let j = 0; j < year - fromYears[i]; j++) {
            saving *= (1 + incrementPercs[i] / 100);
          }
          // Update the total income for the corresponding year
          savingData[year - minYear] += Math.round(saving);
        }

      }

      
      return savingData;
    },


    calculateIndividualSaving() {
      // Check if any of the required arrays are empty
      if (
        !this.SavingAmount ||
        !this.SavingNewAmount ||
        !this.savChangePercFromStore ||
        !this.savfromFromStore ||
        !this.savtillFromStore ||
        !this.savFreqFromStore ||
        !this.savIncrementPercFromStore ||
        this.SavingAmount.length === 0 ||
        this.SavingNewAmount.length === 0 ||
        this.savChangePercFromStore.length === 0 ||
        this.savfromFromStore.length === 0 ||
        this.savtillFromStore.length === 0 ||
        this.savFreqFromStore.length === 0 ||
        this.savIncrementPercFromStore.length === 0
      ) {
        // Return an empty array or any default value if the arrays are empty or not available
        return [];
      }

      const savingAmountEntries = this.SavingAmount; // Access the total saving amounts from the computed property
      const savingNewAmountEntries = this.SavingNewAmount; // Access the total saving amounts from the computed property
      const changePercs = this.savChangePercFromStore; // Access the change percentages
      const fromYears = this.savfromFromStore; // Access the starting years
      const tillYears = this.savtillFromStore; // Access the ending years
      const frequencies = this.savFreqFromStore; // Access the frequencies
      const incrementPercs = this.savIncrementPercFromStore; // Access the annual increment percentages

      // Determine the range of years
      const minYear = Math.min(...fromYears);
      const maxYear = Math.max(...tillYears);
      const numYears = maxYear - minYear + 1;

      // Initialize array to store total saving for each year for each source
      const savingData = [];

      for (let i = 0; i < savingAmountEntries.length; i++) {
        const name = this.savingList[i].name; // Accessing the name property
        let changePerc = changePercs[i];
        changePerc = 1 + changePerc / 100; // Increase or match inflation

        let annualFrequentSaving = savingNewAmountEntries[i]; // Initialize with the recurring contribution

        // Adjust annual saving based on frequency
        switch (frequencies[i]) {
          case "Monthly":
            annualFrequentSaving *= 12; // Convert monthly contribution to annual saving
            break;
          case "Quarterly":
            annualFrequentSaving *= 4; // Convert daily contribution to annual saving
            break;
          case "Half-Yearly":
            annualFrequentSaving *= 2; // Convert weekly contribution to annual saving
            break;
          // If frequency is not recognized, assume it's already in annual format
        }

        // Add the opening balance to the adjusted annual saving
        let totalSavingForYear = savingAmountEntries[i] + annualFrequentSaving;

        // Initialize array to store total saving for each year for current source
        const savingForSource = new Array(numYears).fill(0);

        // Calculate the total saving for each year with compound interest and additional contribution
        for (let year = fromYears[i]; year <= tillYears[i]; year++) {
          // Apply the annual increment to the annual saving
          annualFrequentSaving *= (1 + incrementPercs[i] / 100);

          // Calculate the interest for the current year
          let interestForYear = totalSavingForYear * (changePerc - 1);

          // Add the interest to the total saving for the current year
          totalSavingForYear += interestForYear;

          // Add the saving to the corresponding year for the current source
          savingForSource[year - minYear] = Math.round(totalSavingForYear);

          // Add the recurring contribution to the total saving for the next year
          totalSavingForYear += annualFrequentSaving;
        }

        // Push the saving data for the current source to the main saving data array
        // savingData.push(savingForSource);
        savingData.push({ data: savingForSource, name });
      }

      
      return savingData;
    },

    calculateSavingLabels() {
      // Check if any of the required arrays are empty
      if (
        !this.SavingAmount ||
        !this.savChangePercFromStore ||
        !this.savfromFromStore ||
        !this.savtillFromStore ||
        !this.savFreqFromStore ||
        this.SavingAmount.length === 0 ||
        this.savChangePercFromStore.length === 0 ||
        this.savfromFromStore.length === 0 ||
        this.savtillFromStore.length === 0 ||
        this.savFreqFromStore.length === 0
      ) {
        // Return an empty array or any default value if the arrays are empty or not available
        return [];
      }
      const fromYears = this.savfromFromStore; // Access the starting years
      const tillYears = this.savtillFromStore; // Access the ending years

      const labels = [];
      for (let year = fromYears[0]; year <= Math.max(...tillYears); year++) {
        labels.push(year);
      }

      
      return labels;
    },

    combinedData() { // needed for showing in Overview graph
      const combinedData = [];
      
      combinedData.push({ 
        name: 'Total Income',
        data: this.calculateIncome
      });
      combinedData.push({ 
        name: 'Total Expense',
        data: this.calculateExpense
      });
      combinedData.push({ 
        name: 'Accumulated Savings',
        data: this.calculateSaving
      });


      const globalStore = useGlobalStore();
      const retirementYear = globalStore.retirementAge; 
      const labels = this.overviewLabels; // Fetch the labels (representing age)

      // Create the "Left to allocate" data truncated to retirementYear
      const leftToAllocateData = this.leftToAllocatePerYear.map((value, index) => {
        // Only include "Left to allocate" data up to the retirement year
        const yearLabel = labels[index];
        if (yearLabel <= retirementYear) {
            return value;  // Keep the value up to the retirement year
        } else {
            return null; // Hide it after retirement year
        }
      });

      combinedData.push({ 
        name: 'Left to allocate',
        data: leftToAllocateData
      });

      return combinedData;
    },

    totalSavingAmount() {
      // Check if any of the required arrays are empty
      if (!this.savingList || this.savingList.length === 0) {
        // Return 0 if the incomeList is empty or not available
        return 0;
      }
      
      // Get the earliest year from the fromValues array
      const earliestYear = Math.min(...this.savfromFromStore);
      
      // Filter the incomeList to include only entries for the earliest year
      const savingForEarliestYear = this.savingList.filter(saving => parseInt(saving.from) <= earliestYear && earliestYear <= parseInt(saving.till));
      
      // Calculate the total income amount for the earliest year
      const totalSavingForEarliestYear = savingForEarliestYear.reduce((total, saving) => total + parseInt(saving.amount), 0);

      
      // Return the total income amount for the earliest year
      return totalSavingForEarliestYear;
    },

    differenceAmount() {
      let income = this.totalIncomeAmount;
      let expense = this.totalExpenseAmount;
      let diff = income - expense ;
      return diff;
    },

    totalSavingNewAmount() {
      // Check if any of the required arrays are empty
      if (!this.savingList || this.savingList.length === 0) {
        // Return 0 if the incomeList is empty or not available
        return 0;
      }
      
      // Get the earliest year from the fromValues array
      const earliestYear = Math.min(...this.savfromFromStore);
      
      
      // Initialize the total new amount
      let totalNewAmount = 0;

      // Iterate through each saving entry
      this.savingList.forEach(saving => {
        
        // Calculate the total new amount for the current year based on frequency
        switch (saving.frequency) {
          case "Annual":
            
            if (earliestYear >= saving.from && earliestYear <= saving.till) {
              totalNewAmount += parseInt(saving.newAmount); // Parse newAmount to ensure it's treated as a number
            }
            break;
          case "Monthly":
            
            if (earliestYear >= saving.from && earliestYear <= saving.till) {
              totalNewAmount += parseInt(saving.newAmount) * 12; // Parse newAmount to ensure it's treated as a number
            }
            break;
          // Add cases for other frequencies as needed
        }
      });

      
      return totalNewAmount;
    },
    leftToAllocate() { // needed for showing left to allocate amount in Savings box for current year
      const diff = this.totalIncomeAmount - this.totalExpenseAmount - this.totalSavingNewAmount;
      return diff;
    },
    leftToAllocatePerYear() { // needed for showing left to allocate amount in Overview Graph
      // Get the income, expense, and saving data arrays
      const incomeData = this.calculateIncome; // Array of income for each year
      const expenseData = this.calculateExpense; // Array of expense for each year
      const savingData = this.calculateOnlyAnnualSaving; // Array of saving for each year
      const globalStore = useGlobalStore();
      const retirementYear = globalStore.retirementAge; // Assume retirementYear is available

      // Determine the range of years from income, expense, and savings
      const minYear = Math.min(...this.incfromFromStore, ...this.expfromFromStore, ...this.savfromFromStore);
      // const maxYear = Math.max(...this.inctillFromStore, ...this.exptillFromStore, ...this.savtillFromStore);
      const maxYear = Math.min(Math.max(...this.inctillFromStore, ...this.exptillFromStore, ...this.savtillFromStore), retirementYear); // Stop at retirement year
      const numYears = maxYear - minYear + 1;

      // Initialize an array to store "left to allocate" values for each year
      const leftToAllocateData = new Array(numYears).fill(0);

      // Iterate over each year and calculate the "left to allocate" for each
      for (let i = 0; i < numYears; i++) {
        // Calculate "left to allocate" for each year: Income - Expense - Savings
        leftToAllocateData[i] = (incomeData[i] || 0) - (expenseData[i] || 0) - (savingData[i] || 0);
      }

      
      return leftToAllocateData;
    },
    
  },
};
</script>

<style scoped>

.icon-title-card {
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 1px;
}

.icon-background {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px; /* Adjust the width as needed */
  min-width: 48px;
  height: 48px; /* Adjust the height as needed */
  /* Default background color background-color: rgb(207, 73, 73); */
}

.title-content {
  flex-grow: 1;
  padding-left: 1rem; /* Add spacing between the icon and title */
  padding-top: 1rem;
}

.card-border {
  border: 1px solid #ccc; /* Set the border properties as desired */
  border-radius: 1px; /* Adjust the border radius as needed for curved corners */
  
}


.income-hover-color:hover {
  background-color: rgb(183, 183, 255); /* Change the background color on hover */
  /* You can use any color code or class here */
}

.expense-hover-color:hover {
  background-color: rgb(243, 181, 185); /* Change the background color on hover */
  /* You can use any color code or class here */
}

.savings-hover-color:hover {
  background-color: rgb(180, 250, 196); /* Change the background color on hover */
  /* You can use any color code or class here */
}



</style>